.backdrop {
    z-index: 1201 !important;
}

.userPermission {
    font-size: 1rem;
    color: #676870;
    padding: 12px 24px;
    min-width: 48px;
    background-color: rgba(38, 40, 52, 0.1);
    border-radius: 10px;
    text-align: center;
}

.userPermissionSelect {
    font-size: 1rem;
    color: white;
    padding: 12px 24px;
    min-width: 48px;
    background-color: #f60;
    border-radius: 10px;
    text-align: center;
}
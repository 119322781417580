.expandParam {
    background-color: white;
    display: flex;
    padding: 16px;
    margin-top: 16px;
    border-radius: 6px;
    flex-wrap: wrap;
}

.expandParam>div {
    margin: 4px;
}

.usersBulk {
    /* padding: 16px; */
    margin-top: 16px;
    /* height: 400px; */
}

.tableTitle {
    padding-left: 0px !important;
    font-weight: normal !important;
    color: #676870 !important;
    font-size: 1rem !important;
}

.space {
    flex-grow: 1;
}

.listRow {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    display: flex;
    align-items: stretch;
    color: rgba(0, 0, 0, 0.87);
}

.listRow>div {
    display: flex;
    align-items: center;
}

.listItemPaddding {
    text-indent: 18px;
}

.listItemPaddding2 {
    padding-left: 18px;
}

.listTitle {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    background-color: #fafafa;
}

.listFinger {
    width: 12%;
    justify-content: start;
}

.listAuth {
    width: 11%;
    justify-content: start;
}

.listName {
    width: 22%;
}

.listCell.MuiGrid-container {
    align-items: center;
}

.tableTitleIcon {
    padding: 0px !important;
    /* padding-top: 0px !important;
    padding-bottom: 0px !important; */
}

.tableTitleIcon>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tableTitleFont {
    font-weight: normal !important;
    color: #676870 !important;
    font-size: 1rem !important;
    padding-left: 17px;
}

.thdiv {
    border-left-color: #d4d4d6;
    border-left-width: 2px;
    border-left-style: solid;
    padding-left: 14px;
}

.thdivIcon {
    border-left-color: #d4d4d6;
    border-left-width: 2px;
    border-left-style: solid;
    padding-left: 16px;
    padding-right: 16px;
    display: inline-block;
    width: 100%;
}

.thdivIcon>button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formatName {
    max-width: 200px;
    color: #676870;
}

.formatName2 {
    max-width: 200px;
}

.createUser {
    border-radius: 100px;
    font-weight: bold;
}

.table {
    flex-grow: 1;
    margin-bottom: 40px;
    margin-top: 16px;
}

.tableContent>.MuiTableCell-body {
    color: #676870;
}

td>.MuiTypography-root {
    /* font-weight: bold; */
}

td>div>.MuiTypography-root {
    /* font-weight: bold; */
}

.iconCell {
    padding: 0px !important;
}

.dashboardMargin {
    margin-top: 50px !important;
}

.dashboardTitle {
    font-size: 1.123rem !important;
    font-weight: 'blod' !important;
    color: #676870 !important;
}

.chartLegendColor {
    color: #8c8c8c;
    font-size: 1rem;
}

.MuiTooltip-popper>div {
    font-size: 0.875rem;
}

.buttonFontBlod {}

.buttonFontBlod>span.MuiButton-label {
    font-weight: normal;
}

.tablecellFont {
    color: #676870;
    font-size: 1rem;
    display: inline-block;
}

.bulkNumberRound {
    margin-left: 18px;
    border-radius: 100px;
    background: #fee8a9;
    color: white;
    padding: 4px 12px;
}

.bulkNumberRound2 {
    border-radius: 100px;
    background: #ffc299;
    color: white;
    padding: 4px 12px;
}

.textCenter {
    text-align: center;
}

.cluster>div {
    outline: none;
}

.avatar {
    width: 100px;
}

.userBoxLabel {
    font-weight: 500;
    font-size: 1.125rem;
    color: #676870;
}

.customeButton {
    min-width: 80px !important;
    border-radius: 5px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 13px !important;
}

.hideExpansionPanel {}

.hideExpansionPanel::before {
    background-color: transparent !important;
}

.formatName3 {
    position: relative;
    height: 1.5rem;
}

.formatName3>:first-child {
    position: absolute;
    max-width: 100%;
    
}

.tabelheight {
    position: relative;
    height: 51px;
}

@media print {
    .noprint {
        visibility: hidden;
        height: 1px;
    }
}

.tablePaper {
    box-shadow: 0px 0px 10px rgba(38, 40, 52, 0.2) !important;
}

.tableHeaderPadding {
    padding-top: 2px;
    padding-bottom: 3px;
    padding-left: 14px;
}

.fvIcon {
    position: absolute;
}

.tableBreaker {
    height: 1.5rem;
    position: absolute;
    border-left: 2px solid #d4d4d6;
    left: 0PX;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
}